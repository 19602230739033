import {
  IonModal,
  IonText,
  IonCard,
  IonItem,
  IonIcon,
  IonInput,
  IonTextarea,
  IonLabel,
  IonCheckbox,
  IonFooter,
  IonButton,
  IonFab
} from "@ionic/react";

import React, { useState, useContext, useEffect } from "react";

import { call, person, at, text } from "ionicons/icons";
import AuthContext from "../context/authContext";
const publicar = async (
  condolencia: any,
  state: {
    value: any;
    set: Function;
  },
  setShow: Function
) => {
  console.log(condolencia);
  var formdata = new FormData();

  for (const key in condolencia) {
    formdata.append(key, condolencia[key]);
  }
  try {
    await fetch("https://orglaesperanza.com/camaras/php/condolencias.php", {
      method: "POST",
      body: formdata,
      credentials: "include",
      mode: "cors"
    })
      .then((res: any) => res.json())
      .then((res: any) => {
        if (res.codigo >= 400) throw res.mensaje;
        else {
          state.set({ ...state.value, loading: false });
          setShow(false);
        }
      })
      .catch((err: any) => {
        throw err;
      });
  } catch (error) {
    state.set({
      ...state.value,
      loading: false,
      error: "No se pudo publicar"
    });
  }
};
const CondolenciaModal: React.FC<{ showModal: boolean; setShowModal: any }> = ({
  showModal,
  setShowModal
}) => {
  const [publicarState, setPublicarState] = useState({
    data: null,
    loading: false,
    error: null
  });

  const [condolencia, setCondolencia] = useState<any>({
    pin: "",
    nombre: "",
    correo: "",
    telefono: "",
    mensaje: "",
    terminos: false
  });

  const { isAuth, loginForm } = useContext<any>(AuthContext.Context);

  useEffect(() => {
    if (isAuth) setCondolencia({ ...condolencia, ...loginForm.value });
  }, [isAuth]);

  return (
    <IonModal
      isOpen={showModal}
      onWillDismiss={e => {
        console.log(e);
        e.preventDefault();
        setShowModal(false);
      }}
    >
      <div
        style={{
          overflow: "auto"
        }}
      >
        <IonText className="ion-text-center">
          <p>
            <img
              src="./assets/logo.svg"
              style={{ height: "5em" }}
              alt="Organización La Esperanza"
            />
          </p>
        </IonText>

        <IonText className="ion-text-area ion-text-center">
          <h4 className=" ion-padding-horizontal">
            <b>Mensaje de condolencia</b>
          </h4>
        </IonText>

        <IonCard color="light">
          <IonItem lines="none">
            <IonIcon
              color="medium"
              slot="start"
              mode="md"
              icon={person}
            ></IonIcon>
            <IonInput
              value={condolencia.nombre}
              onIonChange={(e: any) => {
                setCondolencia({
                  ...condolencia,
                  nombre: e.detail.value
                });
              }}
              type="text"
              placeholder="Nombre completo"
            />
          </IonItem>
        </IonCard>
        <IonCard color="light">
          <IonItem lines="none">
            <IonIcon color="medium" slot="start" icon={at}></IonIcon>
            <IonInput
              value={condolencia.correo}
              onIonChange={(e: any) => {
                setCondolencia({
                  ...condolencia,
                  correo: e.detail.value
                });
              }}
              type="email"
              placeholder="Correo electrónico"
            />
          </IonItem>
        </IonCard>
        <IonCard color="light">
          <IonItem lines="none">
            <IonIcon color="medium" slot="start" icon={call}></IonIcon>
            <IonInput
              value={condolencia.telefono}
              onIonChange={(e: any) => {
                setCondolencia({
                  ...condolencia,
                  telefono: e.detail.value
                });
              }}
              type="tel"
              placeholder="Teléfono"
            />
          </IonItem>
        </IonCard>
        <IonCard color="light">
          <IonItem lines="none">
            <IonIcon color="medium" slot="start" icon={text}></IonIcon>
            <IonTextarea
              value={condolencia.mensaje}
              onIonChange={(e: any) => {
                setCondolencia({
                  ...condolencia,
                  mensaje: e.detail.value
                });
              }}
              autoGrow
            ></IonTextarea>
          </IonItem>
        </IonCard>
        <hr />
        <IonCard color="light">
          <IonItem lines="none">
            <IonLabel>Acepto los términos y condiciones</IonLabel>
            <IonCheckbox
              onIonChange={(e: any) => {
                setCondolencia({
                  ...condolencia,
                  terminos: e.detail.checked
                });
              }}
              color="primary"
            />
          </IonItem>
        </IonCard>
        <IonCard className="ion-no-margin ion-no-padding">
          <IonText className="ion-no-margin  ion-text-center">
            <p className="ion-no-margin ion-no-padding">
              <a
                href="http://www.organizacionlaesperanza.com/terminos_condiciones.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Términos y condiciones
              </a>
            </p>
          </IonText>
        </IonCard>
      </div>

      <IonFooter className="ion-padding">
        <IonButton
          expand="block"
          onClick={() => {
            publicar(
              condolencia,
              {
                value: publicarState,
                set: setPublicarState
              },
              setShowModal
            );
          }}
        >
          Enviar condolencia
        </IonButton>
      </IonFooter>
    </IonModal>
  );
};

export default CondolenciaModal;
