import {
  IonContent,
  IonPage,
  IonFab,
  IonFabButton,
  IonMenu,
  IonMenuToggle,
  IonIcon,
  IonSpinner,
  IonLoading,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonToast,
} from "@ionic/react";

import React, { useState, useEffect, useContext } from "react";
import LoginModal from "../modals/loginModal";
import ObituarioMenu from "../modals/obituarioMenu";
import AuthContext from "../context/authContext";
//@ts-ignore
import VideoPlayer from "react-video-js-player";
import ReactPlayer from "react-player";
import "videojs-logo";

import io from "socket.io-client";

import {
  person,
  volumeHigh,
  volumeMute,
  volumeOff,
  camera,
  videocam,
} from "ionicons/icons";
import useDeviceOrientation from "../hooks/deviceOrientation";
import useIsMobileBrowser from "../hooks/mobileBrowser";

type loginForm = {
  pin: string;
  nombre: string;
  correo: string;
  telefono: string;
  terminos: boolean;
};

type authContext = {
  isAuth: boolean;
  login: Function;
  loginState: {
    data: any;
    loading: boolean;
    error: any;
  };
  checkSession: Function;
  codigo: { value: string; set: any };
  loginForm: { value: loginForm; set: any };
};

const Home: React.FC<{ match: { params: any } }> = ({ match }) => {
  // const [showModal, setShowModal] = useState(true);
  const [isShowingLiveToast, setIsShowingLiveToast] = useState(false);

  const [camara, setCamara] = useState("Sala");
  const [isLive, setLive] = useState(false);
  const [isMuted, setMuted] = useState(true);
  const [salaNombre, setSalaNombre] = useState("");

  const deviceOrientation = useDeviceOrientation();
  const isMobileBrowser = useIsMobileBrowser();

  const { isAuth, codigo, loginForm } = useContext<authContext>(
    AuthContext.Context
  );
  var timer: any = null;
  useEffect(() => {
    codigo.set(match.params.codigo);
  }, [codigo, match.params.codigo]);

  useEffect(() => {
    console.log("Inicio sesion", isAuth);
  }, [isAuth]);

  useEffect(() => {
    if (!isLive && isAuth) {
      console.log("consultando video");
      if (loginForm.value.pin && codigo.value) {
        let i = 0;
        timer = setInterval(async () => {
          i = i + 1;
          await fetch(
            `http://rtmp.orglaesperanza.com:8200/live/` +
              codigo.value +
              "-" +
              loginForm.value.pin +
              `/index.m3u8`
          ).then((res: any) => {
            if (res.ok) {
              clearInterval(timer);
              setIsShowingLiveToast(true);
              setLive(true);
            } else {
              setLive(false);
            }
          });
        }, 1500);
      }
    }
  }, [isAuth, isLive, loginForm.value.pin, codigo.value]);

  useEffect(() => {
    fetch(
      `https://orglaesperanza.com/camaras/php/obituario.php?obituarioId=` +
        atob(match.params.codigo)
    )
      .then((res: any) => res.json())
      .then((res) => {
        setSalaNombre(res.velacion_url);
      });
  }, []);
  return (
    <IonPage>
      <IonToast
        isOpen={isShowingLiveToast}
        onDidDismiss={() => setIsShowingLiveToast(false)}
        message="Cámara exterior disponible"
        translucent={true}
        duration={5000}
      />

      {isAuth && (
        <IonMenu
          type="overlay"
          side="end"
          contentId="test"
          class="my-custom-menu"
        >
          <ObituarioMenu
            menuMode={true}
            id={atob(match.params.codigo)}
          ></ObituarioMenu>
        </IonMenu>
      )}

      {isAuth === false && <LoginModal></LoginModal>}

      <IonContent id="test">
        {deviceOrientation === "landscape" && !isMobileBrowser && (
          <IonFab horizontal="end" vertical="top">
            <IonMenuToggle menu="end">
              <IonFabButton>
                <IonIcon icon={person} mode="md"></IonIcon>
              </IonFabButton>
            </IonMenuToggle>
          </IonFab>
        )}

        <IonFab horizontal="start" vertical="top">
          <IonButton mode="ios" color="light" size="small">
            <IonIcon icon={videocam} mode="md"></IonIcon>
            <IonSelect
              value={camara}
              interface="popover"
              onIonChange={(e) => setCamara(e.detail.value)}
              style={{ paddingBottom: "0.5em" }}
            >
              <IonSelectOption value="Exterior">Exterior</IonSelectOption>
              <IonSelectOption value="Sala">Sala</IonSelectOption>
            </IonSelect>
          </IonButton>
        </IonFab>

        <IonFab horizontal="end" vertical="bottom">
          <IonMenuToggle menu="end">
            <img
              style={{ width: "10vw", opacity: "0.6" }}
              src={`${process.env.PUBLIC_URL}/logo-tinta.svg`}
              alt=""
            />
          </IonMenuToggle>
        </IonFab>
        <div
          style={{
            height: deviceOrientation === "portrait" ? "56.25vw" : "100%",
            background: "black",
            width: "100%",
            overflow: "hidden",
          }}
        >
          {isAuth && codigo.value && loginForm.value.pin && !isLive && (
            <IonFab horizontal="center" vertical="center">
              <IonSpinner color="light" name="crescent" />
            </IonFab>
          )}
          {(() => {
            if (isAuth && codigo.value && loginForm.value.pin) {
              switch (camara) {
                case "Sala":
                  return (
                    <img
                      src={`http://186.180.73.51:8110/cam/${salaNombre}/`}
                      style={{ width: "100%", height: "100%" }}
                      onLoad={() => setLive(true)}
                    ></img>
                  );
                case "Exterior":
                  if (isLive)
                    return (
                      <ReactPlayer
                        playing={isLive}
                        muted={isMuted}
                        url={
                          `http://rtmp.orglaesperanza.com:8200/live/` +
                          codigo.value +
                          "-" +
                          loginForm.value.pin +
                          `/index.m3u8`
                        }
                        width="100%"
                        height="100%"
                        onError={(e) => {
                          console.log("error", e);
                          setLive(false);
                        }}
                      />
                    );
              }
            } else {
              return (
                <div
                  style={{ height: "100%", width: "100%", background: "black" }}
                >
                  <IonFab horizontal="center" vertical="center">
                    <IonSpinner color="light" name="crescent" />
                  </IonFab>
                </div>
              );
            }
          })()}
        </div>
        {deviceOrientation === "portrait" && (
          <ObituarioMenu id={atob(match.params.codigo)}></ObituarioMenu>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Home;
